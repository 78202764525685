import { Link } from "gatsby";
import React from "react";

//props/variables are fed in from page.js

const Footer = ({ siteTitle, site }) => (
  <footer>
    <div className="bg-blue-900 w-full grid pt-12 pb-4 px-8 gap-y-4 grid-cols-1 md:grid-cols-2 items-center">
      <div className="links">
        <div className="grid md:flex text-sm md:text-base items-center">
          <Link to={"/about"} >
            About
          </Link>
          <div className="hidden md:block">&nbsp;•&nbsp;</div>
          <Link to={"/services"} >
            Services
          </Link>
          <div className="hidden md:block">&nbsp;•&nbsp;</div>
          <Link to={"/partnerships"} >
            Partnerships
          </Link>
          <div className="hidden md:block">&nbsp;•&nbsp;</div>
          <Link to={"/careers"} >
            Careers
          </Link>
        </div>
      </div>
      <div className="md:text-right">
        <div className="grid md:flex text-sm md:text-base items-center md:justify-end">
          <a className="mr-4" href={`mailto: ${site.email}`}>
            {site.email}
          </a>
          {site.facebook ? (
            <div className="mt-2 md:mt-0 w-6 mr-4"><a href={site.facebook} target="_blank" rel="noopener noreferrer"><img src="/img/facebook.svg" alt="Facebook icon and link"/></a></div>
          ) : null}
          {site.twitter ? (
            <div className="mt-2 md:mt-0 w-6 mr-4"><a href={site.twitter} target="_blank" rel="noopener noreferrer"><img src="/img/twitter.svg" alt="Twitter icon and link"/></a></div>
          ) : null}
          {site.instagram ? (
            <div className="mt-2 md:mt-0 w-6 mr-4"><a href={site.instagram} target="_blank" rel="noopener noreferrer"><img src="/img/instagram.svg" alt="Instagram icon and link"/></a></div>
          ) : null}
          {site.linkedIn ? (
            <div className="mt-2 md:mt-0 w-6 mr-4"><a href={site.linkedIn} target="_blank" rel="noopener noreferrer"><img src="/img/linkedin.svg" alt="Linkedin icon and link"/></a></div>
          ) : null}
        </div>
      </div>
    </div>
    <div className="bg-blue-900 w-full grid grid-flow-col pt-4 pb-10 px-8 md:justify-items-center items-center">
      <div className="grid grid-cols-1 md:flex row-gap-3 text-sm md:text-base items-center">
          <Link to={"/docs/privacy-policy.html"}>
            Privacy Policy
          </Link>
          <div className="hidden md:block">
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </div>
          <Link to={"/docs/terms-of-use.html"}>
            Terms Of Use
          </Link>
          <div className="hidden md:block">
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </div>
          <Link to={"/sitemap.xml"}>
            Sitemap
          </Link>
          <div className="hidden md:block">
            &nbsp;&nbsp;|&nbsp;&nbsp;
          </div>
          <p className="text-gray-500">
            © {new Date().getFullYear()},
            {` `}
            Unified Enterprise Corp.
          </p>
        </div>
    </div>
  </footer>
);

export default Footer;
