import React from "react"

//Components
//import { Link } from "gatsby"
import { Link } from "gatsby"
import { motion, useCycle } from "framer-motion"
import { MenuToggle } from "./menu-toggle"
import CTALink from "./CTALink"

//Styles
import * as styles from "./header.module.css";

//props/variables are fed in from page.js

//moble nav
const variants = {
  open: { 
    opacity: 1, y: 0,
    pointerEvents: "inherit"
  },
  closed: { 
    opacity: 0, y: "100%",
    pointerEvents: "none"
  },
}

const MobNav = ({ navMenuItems }) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  
  return (
    <div>
      <motion.div
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{
          type: "tween",
          delay: 0,
          duration: 0.6,
          ease: [0.44, 0, 0.56, 1],
        }}
        className={styles.mobnavcontainer}
      >
        <motion.div
          style={{
              backgroundColor: "#60F",
              height: "100vh"
          }}
          animate={{ backgroundColor: ["#002d43", "#002d43", "#002d43"] }}
          transition={{ duration: 2, yoyo: Infinity }}
        >
          <div className={styles.mobnav}>
          <motion.button
            className={`${styles.mobilelink} outline-none focus:outline-none`}
            whileTap={{ scale: 0.8 }}
            key={"home"}
            >
              <Link activeClassName="underline" className={`mx-auto font-bold text-white`} to={`/`}>
                Home
              </Link>
          </motion.button>
            {navMenuItems.map(i => (
              <motion.button
              className={`${styles.mobilelink} outline-none focus:outline-none`}
              whileTap={{ scale: 0.8 }}
              key={i.title}
              >
                <CTALink {...i} 
                  linkActionClass="mx-auto font-bold" rel="noopener noreferrer"  
                />
              </motion.button>
            ))} 
            
          </div>
        </motion.div>
      </motion.div>
      <motion.div 
        className={`${styles.mobnavbutton} outline-none focus:outline-none p-6 z-50`}
        initial={false}
        animate={isOpen ? "open" : "closed"}
      >
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.div>
    </div>
  )
}

export default MobNav