import React from "react";
import { Link, navigate } from "gatsby";
import { motion } from "framer-motion"

//props/variables are fed in from page.js

const doNavigate = target => {
  if (!target || !target.length) {
    return;
  }
  const internal = /^\/(?!\/)/.test(target);
  if (internal) {
    navigate(target);
  } else {
    window.location = target;
  }
};

//CTALink takes the first link and returns it. 
const CTALink = props => {
  let link = props.route || props.link || "#";
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = props.landingPageRoute.slug.current;
  }

  if (props.kind === "button") {
    if (props.email) {
      return (
        <motion.button whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} className={`${props.buttonActionClass} outline-none focus:outline-none` || ""}>
          <a href={`mailto: ${props.email}`}>
            {props.title}
          </a>
        </motion.button>
      );
    }
    // External
    if (props.link) {
      return (
        <motion.button whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} className={`${props.buttonActionClass} outline-none focus:outline-none` || ""}>
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            {props.title}
          </a>
        </motion.button>
      );
    }
    return (
      <motion.button 
      whileHover={{ scale: 1.2 }} 
      whileTap={{ scale: 0.8 }} 
      id="navAction"
      onClick={() => doNavigate(`/${link}/`)}
      className={`${props.buttonActionClass} outline-none focus:outline-none` || ""}>
        {props.title}
      </motion.button>
    );
  }

   //Email
   if (props.email) {
    return (
      <a className="underline" className={`${props.linkActionClass} text-green-500` || ""} href={`mailto: ${props.email}`}>
        {props.title}
      </a>
    );
  }

  // External
  if (props.link) {
    return (
      <a href={props.link} target="_blank" className={`${props.linkActionClass} text-green-500` || ""} rel="noopener noreferrer">
        {props.title}
      </a>
    );
  }

 

  return (
    <Link activeClassName="underline" className={`${props.linkActionClass} text-white` || "mr-3"} to={`/${link}/`}>
      {props.title}
    </Link>
  );
};

export default CTALink;
