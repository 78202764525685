import React from "react";
import Header from "./header";
import Footer from "./footer";
import CookieConsent from 'react-cookie-consent';
import "../styles/layout.css";

//props/variables are fed in from page.js

//this component wraps around all components on a page
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (this.state.scrolled && window.scrollY <= 10) {
      this.setState({ scrolled: false });
    } else if (!this.state.scrolled && window.scrollY > 10) {
      this.setState({ scrolled: true });
    }
  };

  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      siteTitle,
      site,
      navMenuItems,
      navPlacement,
      textWhite = true
    } = this.props;
    const { scrolled } = this.state;
    //console.log(site)
    return (
      <>
        <Header
          navMenuItems={navMenuItems}
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          scrolled={scrolled}
          textWhite={textWhite}
          navPlacement={navPlacement}
        />
        <>{children}</>
        <Footer siteTitle={siteTitle} site={site}/>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          disableStyles={true}
          buttonClasses="mx-auto border-2 border-green-500 text-white-800 font-bold my-6 py-4 px-8 shadow-lg"
          containerClasses="bg-white z-40 py-8 md:py-16 md:flex items-center justify-between fixed bottom-0 w-full px-10 left-0 text-black"
          contentClasses="text-capitalize grid md:flex items-center grid-cols-1 md:grid-cols-3"
        >
          <div className="text-3xl md:text-4xl font-bold leading-tight mb-4 mr-10 text-green-500">Disclaimer:</div>
          <div className="md:col-span-2 pr-6">
            Our privacy policy and terms of use are changing to better explain the data we collect,
            how we use it, and to give you more information about your rights and choices with respect to your data.
            By continuing to use our services, you accept our new privacy policy and terms of use.
            We encourage you to review our privacy policy and terms of use.
          </div>
        </CookieConsent>
      </>
    );
  }
}

export default Layout;
