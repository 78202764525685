import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

// https://ogp.me

//props/variables are fed in from page.js

function SEO({ description, lang, meta, keywords, title, siteTitle, image, bodyAttr, gradient }) {
  
  const metaDescription = description;
  const siteName = siteTitle;
  const metaImage =
    image && image.asset
      ? imageUrlFor(buildImageObj(image))
          .width(1200)
          .url()
      : "";

  const pageTitle = title || siteName;
  return (
    <Helmet
      bodyAttributes={bodyAttr}
      htmlAttributes={{ lang }}
      title={pageTitle}
      titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
    > 
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaImage} />
      <meta name="keywords" content={keywords.join(", ")} />
      <meta name="robots" content="index, follow"></meta>
      <meta property="og:site_name" content={siteName} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:secure_url" content={metaImage} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:creator" content="@unified" />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaImage} />
      {gradient && gradient.from && gradient.to && (
        <style type="text/css">{`
        .gradient {
          background: linear-gradient(90deg, ${gradient.from} 0%, ${gradient.to} 100%);
        }
      `}</style>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
