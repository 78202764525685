import { Link } from "gatsby";
import React from "react";
import CTALink from "./CTALink";
import MobNav from "./mobile-nav"

//props/variables are fed in from page.js

const Header = ({ showNav, siteTitle, scrolled, navMenuItems = [], navPlacement, textWhite = true }) => {
  

  return (
    <nav id="header" className={`w-full z-3 p-4 ${navPlacement ? '' : 'absolute top-0'}`}>
      <div className="w-full mx-auto flex flex-wrap z-10 items-center justify-between mt-0 py-2">
        <div className="pl-4 z-40 flex items-center">
          <Link id="siteTitle" className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl" to="/">
            <img src="/img/logo.svg" alt={siteTitle} className="h-12 mb-2 fill-current inline" />{" "}
          </Link>
        </div>

        {showNav && navMenuItems && (
          <div className="z-40">
            <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 text-white p-4 lg:p-0 z-20" id="nav-content">
              <ul className="list-reset lg:flex justify-end flex-1 items-center">
                {navMenuItems.map(i => (
                  <li key={i.title} className="mr-3 text-xl font-bold">
                    <CTALink {...i} 
                      buttonActionClass="mx-auto hover:underline border-2 border-green-500 text-white-800 font-bold my-6 py-4 px-8 shadow-lg" 
                      linkActionClass="mx-auto lg:mx-0 font-bold mt-4 lg:mt-0 py-4 px-4" rel="noopener noreferrer"  
                    />
                  </li>
                ))} 
              </ul>
            </div>
            <div className="lg:hidden">
              <MobNav navMenuItems={navMenuItems} />
            </div>
          </div>
        )}
      </div>
      
    </nav>
  );
};

export default Header;
